class LogoCarousel extends elementorModules.frontend.handlers.Base {
  protected swiperInstance: any = null;

  constructor($element: JQuery, args: any) {
    super($element, args);
    const asyncSwiper = elementorFrontend.utils.swiper;
    const swiperElement = this.elements.$swiperContainer;
    const swiperSlides = this.elements.$swiperSlides;

    const swiperConfig = {
      slidesPerView: 2,
      spaceBetween: 32,
      loop: true,
      loopedSlides: 8,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      grabCursor: true,

      breakpoints: {
        768: { slidesPerView: 4, spaceBetween: 32 },
      },
    };
    new asyncSwiper(swiperElement, swiperConfig).then((swiper: any) => {
      this.swiperInstance = swiper;
    });
  }

  getDefaultSettings() {
    return {
      selectors: {
        swiperContainer: ".swiper",
        swiperSlides: ".swiper-slide",
        pagination: ".hco-logoCarousel__pagination",
      },
    };
  }
  getDefaultElements() {
    const selectors = this.getSettings("selectors");
    return {
      $swiperContainer: this.$element.find(selectors.swiperContainer),
      $swiperSlides: this.$element.find(selectors.swiperSlides),
      $pagination: this.$element.find(selectors.pagination),
    };
  }
  bindEvents() {}
}

/**
 * Register JS Handler for the Test Widget
 *
 * When Elementor frontend was initiated, and the widget is ready, register the widet
 * JS handler.
 */
window.addEventListener("elementor/frontend/init", () => {
  elementorFrontend.elementsHandler.attachHandler(
    "gravitas_logoCarousel",
    LogoCarousel
  );
});
